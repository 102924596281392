import { Router } from '@angular/router';
import { CadastroComponent } from '../../default/cadastro.component';
import { DefaultService } from '../../default/default.service';
import { MessageService } from '../../message.service';
import { DescricaoModel } from '../descricao.model';

export abstract class DescricaoCadastroComponent<Model extends DescricaoModel> extends CadastroComponent<DescricaoModel> {

  constructor(
    router: Router,
    defaultService: DefaultService,
    messageService: MessageService,
    navegacao: boolean) {
    super(router,
      defaultService,
      messageService,
      navegacao)
  }

  getModel(): Model {
    return super.getModel() as Model;
  }

  getNovoModel(): DescricaoModel {
    return new DescricaoModel("", "", "Ativo");
  }

  getMaxCount(): number {
    return undefined;
  }

  isValidMaxCount(): boolean {
    return this.getMaxCount() !== undefined && this.getMaxCount() > 0;
  }

  noEdit()
  {
    return true;
  }

  noClear()
  {
    return true;
  }
}
