
export class StringUtils {
  public static hasNumber(value: string): boolean {
    if (value) {
      return value.search('.*[0-9].*') !== -1
    }
    return false
  }

  public static getNumberAtStart(value: string): number {
    let flag = 0;
    for (let i = 0; i < value.length; i++) {
      const v = value[i] as string;
      if (StringUtils.hasNumber(v)) flag++
      else break;
    }
    if (flag) return Number(value.substring(0, flag));
    return undefined
  }

	/** Remove e retorna a string sem caracter especial */
	public static removeSpecials(str: string) {
		var lower = str.toLowerCase();
		var upper = str.toUpperCase();

		var res = "";
		for (var i = 0; i < lower.length; ++i) {
			if (lower[i] != upper[i] || lower[i].trim() === '')
				res += str[i];
		}
		return res;
	}

	/** Verifica se tem caracter especial na string */
	public static containSpecials(str: string) {
		var format = /[ `!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~¨ºª]/;		
		return format.test(str);
	}
	
	/** Remove acento da string */
	public static removeAcento (text: string)	{       
	    //text = text.toLowerCase();                                                         
	    text = text.replace(new RegExp('[ÁÀÂÃÄ]','gi'), 'a');
	    text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
	    text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
	    text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
	    text = text.replace(new RegExp('[ÚÙÛÜ]','gi'), 'u');
	    text = text.replace(new RegExp('[Ç]','gi'), 'c');
	    return text;                 
	}

	/** Remove espaços da string */
	public static removeEspaco (text: string)	{       
	    return text.replace(/\s/g, "");	                  
	}

}


export class ArraysUtils {

  public static removeAll(array: any[], indices: any[]): any[] {
    let i = indices.length;
    while (i--) {
      array = this.remove(array, indices[i])
    }
    return array;
  }
  /**
   * Remove um indice de chaves de outro array, caso eles possuam um campo ID a comparação é feita pelo ID.
   * @param array source array
   * @param indice array to remove
   */
  public static remove(array: any[], indice: any): any[] {
    return array.filter(obj =>
      obj && obj.id && indice && indice.id
        ? obj.id !== indice.id // if has id compare by id
        : obj !== indice)// else do normal compare
  }

  /*
   // Remove pela referencia, mas nao esta terminado, só consegui deixando undefined
  public static remove(array: any[], indice: any) {
    for (let i = 0; i < array.length; i++) {
      const obj = array[i];
      if (obj && obj.id && indice &&
        indice.id && obj.id !== indice.id) {// if has id compare by id
        delete array[i];
      } else if (obj !== indice) {// else do normal compare
        delete array[i];
      }

    }
    return array
  }*/

}
export class SortUtils {

  public static compareExtractinNumber(a: string, b: string, isAsc): number {
    const aNumber = StringUtils.getNumberAtStart(a);
    const bNumber = StringUtils.getNumberAtStart(b);
    if (aNumber && bNumber) {
      const nResult = SortUtils.compare(aNumber, bNumber, isAsc)
      if (nResult !== 0) return nResult;
    }
    return SortUtils.compare(a, b, isAsc)
  }

  public static compare(a, b, isAsc): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

export class DocumentUtils {

  public static verificaEmail(email: string): any {
    if (email && email !== "") {
      return email.search(/@/gi) !== -1 ? '' : 'Não encontrado @'
    }
  }

}



