import { Referencia } from './../../core/centro-custo/Referencia.model';
export class FilterItemsPrescricaoByProcess {
    constructor
        (
            public referencia:string,
            public objeto_prescricao:string, //utilizado para buscar os items da prescrição por medicamento/procedimento
            public processo:String,          //utilizado para buscar os items da prescrição por processo
            public pageIndex: number,        //define apartir de qual valor será buscado os objetos
            public pageSize: number,         //define a quantidade de objetos que será buscado
            public sort: string,             //define por qual atributo será ordenado os objetos
            public direction: string         //define se a ordenação será crescente ou descrescente
        ) 
        { }
}