import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CentroCusto } from './centro-custo.model';
import { CentroCustoFilter } from './centro-custo-filter.model';
import { SGS_URL, CORE } from '@app/app.api';
import { ListaJSON } from '../shared/default/lista-json.model';

@Injectable({
  providedIn: 'root'
})
export class CentroCustoService {

  private _centroCustoSearch: CentroCustoFilter;

  private _unidadeFilter;

  constructor
    (
      private http: HttpClient,
    ) { }

    searchListCentroCusto():Observable<ListaJSON<CentroCusto>> {
      return this.http.post<ListaJSON<CentroCusto>>(`${SGS_URL.CORE}/${CORE.CENTRO_CUSTO}/p`, this._centroCustoSearch);
    }

    getCentrosByNomeOrCCSGGM(centro: string):Observable<CentroCusto[]> {

      return this.http.post<CentroCusto[]>(`${SGS_URL.CORE}/${CORE.CENTRO_CUSTO}/listarCCByNomeSGM/${centro}`, {})
    }

    public get centroCustoSearch(): CentroCustoFilter {
      return this._centroCustoSearch;
    }

    public set centroCustoSearch(value: CentroCustoFilter) {
      this._centroCustoSearch = value;
    }


}
