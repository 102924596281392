import { MotivoSequestroValoresModel } from './motivo-sequestro-valores.model';
import { ContasBancariasModel } from '@app/judicial/contas-bancarias/contas-bancarias-model';
import { Processo } from "../processo/processo.model";
import { ItemPrescricao } from '@app/core/prescricao/item-prescricao.model';

/**
 * Classe modelo para enviar e receber informações do back-end [model rest e dto]
 */
export class SequestroValoresModel
{
    constructor
    (
        public id: string,                         //chave primária do banco de dados
        public data_cadastro: Date,                //campo obrigatório
        public data_descisao: Date,                //campo obrigatório
        public valor:string,                       //campo obrigatório
        public month:string,                       //campo obrigatório
        public item_prescricao:ItemPrescricao [],  //deverá preencher o valor do checkbox com true, se o mesmo estiver contído na classe sequestro de valores
        public processo:Processo,                  //Processo que o sequestro pertence
        public conta_bancaria:ContasBancariasModel, //conta para devolução do valor
        public sgp_e:string,
        public motivo_sequestro:MotivoSequestroValoresModel,
        public status:boolean,                      //Define se o sequestro está excluído ou não
        public observacao,
        public tipo:string,
        public conta_bancaria_destino:ContasBancariasModel,//conta destino do valor
    ){}
}
