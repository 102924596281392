import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoginService } from "@app/core/security/login/login.service";
import { ParametroFilter } from "@app/core/shared/models/parametro-filter";
import { ParametroResponse } from "@app/core/shared/models/parametro-response.model";
import { CORE, SGS_URL } from "@app/app.api";
import { Observable } from "rxjs";
import { ConfiguracaoParametroResponse } from "@app/core/shared/models/configuracao-parametro-response.model";

@Injectable({
  providedIn: "root",
})
export class ParametroService {
  constructor(
    private loginService: LoginService,
    private httpclient: HttpClient
  ) {}

  public getParametroByFiltros(filter: ParametroFilter): Observable<ParametroResponse[]>{
    let _path : string = "";
    _path += (filter.id_usuario    ? filter.id_usuario   : "null");
    _path += "/" + (filter.referencia    ? filter.referencia   : "null");
    _path += "/" + (filter.id_parametro  ? filter.id_parametro : "null");
    _path += "/" + (filter.descricao     ? filter.descricao    : "null");

    return this.httpclient.get<ParametroResponse[]>(`${SGS_URL.CORE}/${CORE.PARAMETROS}/conectacore/parametros/listar/${_path}`);
  }

  public updateParametros(update :ParametroResponse[]): Observable<any>{
    return this.httpclient.post<any>(`${SGS_URL.CORE}/${CORE.PARAMETROS}/conectacore/parametros/update`, update);
  }


  public getConfiguracaoByCodigo(cod_config: string): Observable<ConfiguracaoParametroResponse[]>{
    return this.httpclient.post<ConfiguracaoParametroResponse[]>(`${SGS_URL.CORE}/configuracao-parametro/find-configuracao-parametro-by-cod`,
      {'params': {
        'cod_config': cod_config
      }});
  }
}
