import { AlvaraModel } from '../alvara/alvara.model';
import { SequestroValoresModel } from '../sequestro-valores/sequestro-valores.model';
import { ContasBancariasModel } from '@app/judicial/contas-bancarias/contas-bancarias-model';
import { Processo } from '../processo/processo.model';
import { ItemPrescricao } from '@app/core/prescricao/item-prescricao.model';

export class DevolucaoValoresModel
{
    constructor
    (   public id:string,
        public valor:string,
        public processo:Processo,
        public conta_bancaria:ContasBancariasModel,
        public sequestro_valores: SequestroValoresModel,
        public alvara:AlvaraModel,
        public item_prescricao: ItemPrescricao[],
        public data_cadastro:Date,
        public status:boolean,
        public observacao
    ){}

}
